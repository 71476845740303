import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          "military_courses": "Military Courses",
          "courses": "Courses",
          "course_name": "Course Name",
          "course_id": "Course ID",
          "start_date": "Start Date",
          "end_date": "End Date",
          "reset_date": "Reset Date",
          "page": "Page",
          "pages": "Pages",
          "dashboard": "Dashboard",
          "search": "Search",
          "trademark": "Training Project. All rights reserved.",
          "create_course": "Create Course",
          "details_course": "Course Information",
          "specializations": "Specializations",
          "soldier_number": "Soldier Number",
          "soldier_image": "Soldier Image",
          "soldier_name": "Soldier Name",
          "enrollee_list": "Enrollee List",
          "specialization": "Specialization",
          "enrollee_number": "Enrollee Number",
          "course_categories": "Course Categories",
          "edit_course_details": "Edit Course",
          "delete_course": "Delete Course",
          "edit_course": "Edit Course Information",
          "courses_in_category_progress": "Course Category / Courses",
          "courses_in_category": "Number Of Courses",
          "course_category_number": "Course Category Number",
          "create_courses_category": "Create Course Category",
          "military_course_category": "Military Course Categories",
          "course_category_creation_date": "Courses Category Creation Date",
          "department_number": "Department Number",
          "department_name": "Department Name",
          "number_of_employees_in_department": "Number Of Employees In Department",
          "military_department": "Military Department",
          "create_military_department": "Create Military Department",
          "department_delete": "Department Delete",
          "department_edit": "Edit Department",
          "soldiers": "Soldiers",
          "employees": "Employees",
          "departments_assigned": "Departments Assigned",
          "soldier_date_of_hiring": "Soldier Date Of Hiring",
          "soldier_rank": "Soldier Rank",
          "soldier_full_name": "Soldier Full Name",
          "create_soldier": "Create Soldier",
          "assign_course": "Assign Soldier To Course",
          "assign_soldier": "Assign Soldier",
          "please_select": "Please Select A Choice",
          "unassign_course": "Unassign Course From Soldier",
          "unassign_soldier": "Unassign Soldier",
          "number_of_employees_in_courses_category": "Number Of Employees In Courses Category",
          "no_courses_category": "No  Courses Categories Found",
          "no_enrollee": "No Enrollee Found",
          "no_courses": "No Courses Found",
          "course_status": "Course Status",
          "total_courses": "Total Courses",
          "courses_assigned": "Courses Assigned",
          "course_status_active": "Held",
          "course_status_inactive": "Not held",
          "course_status_completed": "Completed",
          "soilder_status": "Soilder Status",
          "soilder_status_affiliate": "Affiliate",
          "soilder_status_withdrawn": "Withdrawn",
          "soilder_status_candidate": "Candidate",
          "course_mandatory": "Mandatory Course",
          "not_mandatory": "Not Mandatory",
          "mandatory": "Mandatory",
          "select_class": "Select Class",
          "everyone": "Everyone",
          "specific_department": "Specific Department",
          "select_duration": "Select Duration",
          "select_department": "Select Department",
          "lvl2": "Level 2",
          "lvl4": "Level 4",
          "lvl6": "Level 6",
          "lvl8": "Level 8",
          "risk_percentage": "Risk Percentage",
          "edit_courses_category": "Edit Courses Category",
          "edit_course_category": "Edit Course Category",
          "delete_course_category": "Delete Course Category",
          "course_creation_date": "Course Creation Date",
          "soldiers_view": "Soldier View",
          "soldier_level": "Soldier Level",
          "course_assign": "Course Assign",
          "course_assign": "Course Unassign",
          "soldier_edit": "Edit Solider Record",
          "edit_soldier": "Edit Record",
          "edit_assignee_status": "Edit Assignee Status",
          "no_level": "No Level",
          "ERR_MISSING_FIELDS": "Please provide the following missing fields: ",
          "ERR_COURSE_CATEGORY_EXISTS": "Course category with this number already exists.",
          "ERR_INTERNAL_SERVER": "Unexpected error occurred.",
          "ERR_DEPARTMENT_EXISTS": "Department with this name already exists.",
          "ERR_SOLDIER_EXISTS": "Soldier with this number already exists."
        }
      },
      ar: {
        translation: {
          "military_courses": "الدورات العسكرية",
          "courses": "الدورات",
          "course_name": "اسم الدورة",
          "course_id": "رمز الدورة",
          "start_date": "تاريخ البدء",
          "end_date": "تاريخ الانتهاء",
          "reset_date": "اعادة التعيين",
          "page": "صفحة",
          "pages": "صفحات",
          "dashboard": "لوحة التحكم",
          "search": "بحث",
          "trademark": "مشروع التدريب. جميع الحقوق محفوظة.",
          "create_course": "آنشاء دورة",
          "details_course": "معلومات الدورة",
          "specializations": "التخصصات",
          "soldier_name": "اسم العسكري (رباعي)",
          "soldier_image": "صورة العسكري",
          "soldier_number": "رقم العسكري",
          "enrollee_list": "قائمة الملتحقين",
          "specialization": "التخصص",
          "enrollee_number": "عدد الملتحقين",
          "course_categories": "فئة الدورات",
          "edit_course_details": "تعديل الدورة",
          "delete_course": "حذف الدورة",
          "edit_course": "تعديل معلومات الدورة",
          "courses_in_category_progress": "فئة الدورة / الدورة",
          "courses_in_category": "عدد الدورات المسجلة",
          "courses_assigned": "الدورات المسجلة",
          "course_category_number": "رقم فئة الدورة",
          "create_courses_category": "انشاء فئة الدورة جديدة",
          "military_course_category": "فئة الدورة العسكرية",
          "course_category_creation_date": "تاريخ انشاء فئة الدورة",
          "department_number": "رقم التخصص",
          "department_name": "اسم التخصص",
          "number_of_employees_in_department": "عدد الموظفين في التخصص",
          "military_department": "التخصصات العسكرية",
          "create_military_department": "أنشاء تخصص عسكري",
          "department_delete": "حذف التخصص",
          "department_edit": "تعديل التخصص",
          "soldiers": "جناح الشرطة",
          "employees": "الموظفين",
          "departments_assigned": "التخصص المعين عليه",
          "soldier_date_of_hiring": "تاريخ التعيين",
          "soldier_rank": "الرتبة",
          "soldier_full_name": "اسم العسكري الكامل",
          "create_soldier": "انشاء السجل العسكري",
          "assign_course": "اضافة عسكري الى دورة",
          "assign_soldier": "اضافة العسكري",
          "please_select": "الرجاء الاختيار من القائمة",
          "unassign_course": "ازالة دورة من عسكري",
          "unassign_soldier": "ازالة العسكري",
          "number_of_employees_in_courses_category": "عدد الموظفين في فئة الدورة",
          "no_courses_category": "لا يوجد سلامات تعريفية",
          "no_enrollee": "لا يوجد ملتحقين",
          "no_courses": "لا يوجد اي دورات",
          "course_status": "حالة الدورة",
          "course_status_active": "منعقده",
          "course_status_inactive": "تعذر الانعقاد",
          "course_status_completed": "مكتملة",
          "soilder_status": "حالة الحسكري",
          "soilder_status_affiliate": "ملتحق",
          "soilder_status_withdrawn": "منسحب",
          "total_courses": "عدد الدورات",
          "soilder_status_candidate": "مرشح",
          "soilder_assign_date": "تاريخ الالتحاق",
          "course_mandatory": "الدورة اجباري اتمام",
          "not_mandatory": "دورة ليست متطلبة",
          "mandatory": "دورة متطلبة",
          "select_class": "اختر شعبة",
          "everyone": "الكل",
          "specific_department": "قسم مخصص",
          "select_duration": "اختر فترة",
          "select_department": "اختر تخصص",
          "lvl2": "المستوى الثاني",
          "lvl4": "المستوى الرابع",
          "lvl6": "المستوى السادس",
          "lvl8": "المستوى الثامن",
          "risk_percentage": "معدل الخطورة",
          "edit_courses_category": "تعديل فئة الدورات",
          "edit_course_category": "تعديل فئة الدورة",
          "delete_course_category": "حذف فئة الدورة",
          "course_creation_date": "تاريخ انشاء الدورة",
          "soldiers_view": "سجل العسكري",
          "soldier_level": "الفترة الزمنية",
          "course_assign": "اضافة دورة",
          "course_unassign": "حذف دورة",
          "soldier_edit": "تعديل سجل عسكري",
          "edit_soldier": "تحديث السجل",
          "edit_assignee_status": "تحديث حالة العسكري",
          "no_level": "لا يوجد فترة زمنية",
          "ERR_MISSING_FIELDS": "يرجى توفير الحقول المفقودة التالية: ",
          "ERR_COURSE_CATEGORY_EXISTS": "فئة الدورة بهذا الرقم موجودة بالفعل.",
          "ERR_INTERNAL_SERVER": "حدث خطأ غير متوقع.",
          "ERR_DEPARTMENT_EXISTS": "القسم بهذا الاسم موجود بالفعل.",
          "ERR_SOLDIER_EXISTS": "الجندي بهذا الرقم موجود بالفعل."
        }
      }
    }
  });

export default i18n;