import React, { useEffect, useState } from "react";
// Chakra imports
import { Box, Input, Text, VStack, HStack, useColorModeValue, Button, Spinner, Flex } from "@chakra-ui/react";
// Translation
import { useTranslation } from 'react-i18next';
// Axios for HTTP requests
import axios from 'axios';
// React Router
import { useNavigate, useSearchParams } from 'react-router-dom';
// React Toastify for notifications
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Select,
    useChakraSelectProps,
} from "chakra-react-select";
// Custom components
import Card from "components/card/Card";
import DatePicker2 from "../../../../components/datePicker/ReactDatePicker"

export default function Settings() {

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    // Chakra Color Mode
    const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const bgHover = useColorModeValue({ bg: "secondaryGray.400" }, { bg: "whiteAlpha.50" });
    const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

    const { t, i18n } = useTranslation();
    const [courseName, setCourseName] = useState('');
    const [courseId, setCourseId] = useState('');
    const [courseCategory, setCourseCategory] = useState(null);
    const [courseCategoryOptions, setCourseCategoryOptions] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [creationDate, setCreationDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [courseStatus, setCourseStatus] = useState("active");
    const [loadingPage, setLoadingPage] = useState(true);

    const [courseNameErrored, setCourseNameErrored] = useState(false);
    const [courseIdErrored, setCourseIdErrored] = useState(false);
    const [courseCategoryErrored, setCourseCategoryErrored] = useState(false);
    const [startDateErrored, setStartDateErrored] = useState(false);
    const [endDateErrored, setEndDateErrored] = useState(false);

    // New State for Mandatory Course Logic
    const [isMandatory, setIsMandatory] = useState(false);
    const [targetAudience, setTargetAudience] = useState(null);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState('');
    const [selectedDepartmentErrored, setSelectedDepartmentErrored] = useState(false);
    const [selectedLevelErrored, setSelectedLevelErrored] = useState(false);

    const handleUpdate = async () => {
        if (!courseName) {
            toast.error(i18n.language === 'ar' ? 'اسم الدورة مطلوب' : 'Course name is required');
            setCourseNameErrored(true)
            return
        } else setCourseNameErrored(false)

        if (!courseId) {
            toast.error(i18n.language === 'ar' ? 'رقم الدورة مطلوب' : 'Course number is required');
            setCourseIdErrored(true)
            return
        } else setCourseIdErrored(false)

        if (!courseCategory) {
            toast.error(i18n.language === 'ar' ? 'السلامة التعريفية مطلوب' : 'Safety Identification is required');
            setCourseCategoryErrored(true)
            return
        } else setCourseCategoryErrored(false)

        if (!startDate) {
            toast.error(i18n.language === 'ar' ? 'تاريخ البدء مطلوب' : 'Start date is required');
            setStartDateErrored(true)
            return
        } else setStartDateErrored(false)

        if (!endDate) {
            toast.error(i18n.language === 'ar' ? 'تاريخ الانتهاء مطلوب' : 'End date is required');
            setEndDateErrored(true)
            return
        } else setEndDateErrored(false)

        if (isMandatory) {
            if (targetAudience.toLowerCase() === "all") {
                if (!selectedLevel) {
                    setSelectedLevelErrored(true)
                    toast.error(i18n.language === 'ar' ? 'الرجاء اختيار الفترة' : 'Please select a duration.');
                    return
                } else setSelectedLevelErrored(false)
            } else {
                if (!selectedDepartment) {
                    toast.error(i18n.language === 'ar' ? 'الرجاء اختيار القسم' : 'Please select a department');
                    setSelectedDepartmentErrored(true)
                    return
                } else setSelectedDepartmentErrored(false)

                if (!selectedLevel) {
                    setSelectedLevelErrored(true)
                    toast.error(i18n.language === 'ar' ? 'الرجاء اختيار الفترة' : 'Please select a duration.');
                    return
                } else setSelectedLevelErrored(false)
            }
        }

        const course = {
            new_course_id: courseId,
            course_name: courseName,
            start_date: startDate,
            end_date: endDate,
            course_status: courseStatus,
            course_creation_date: creationDate,
            course_last_edited_date: new Date(),
            course_category: courseCategory.map(v => v.value),
            course_mandatory: isMandatory ? {
                level: selectedLevel.value,
                duration: selectedLevel.value === "2" ? 4 : selectedLevel.value === "4" ? 4 : selectedLevel.value === "6" ? 5 : 2,
                department: selectedDepartment ? targetAudience === "specific" ? selectedDepartment.value : null : null
            } : null
        };

        setLoading(true);

        try {
            // Edit the course using PUT method
            await axios.put(`/api/course/${searchParams.get("course")}`, course);

            // Redirect to /admin/courses after successful submission
            navigate('/admin/courses');
        } catch (error) {
            toast.error(i18n.language === 'ar' ? 'خطأ في تعديل الدورة' : 'Error editing course');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Fetch departments if targeting a specific department
        if (targetAudience === "specific") {
            axios.get("/api/departments")
                .then((response) => {
                    setDepartmentOptions(response.data.map((dept) => ({
                        label: dept.department_name,
                        value: dept.department_number,
                    })));
                })
                .catch((error) => {
                    console.error("Error fetching departments:", error);
                    toast.error(t("Failed to load departments"));
                });
        }
    }, [targetAudience]);

    useEffect(() => {
        if (searchParams.get("course")) {
            axios({
                method: 'GET',
                url: `/api/courses?lang=${i18n.language}&course_id=${searchParams.get("course")}`,
            }).then(async (result) => {
                if (result.data.length > 0) {
                    setCourseName(result.data[0].course_name);
                    setCourseId(result.data[0].course_id);
                    setCourseCategory(
                        result.data[0].course_categories.map(e => ({
                            label: e.course_category_number,
                            value: e.course_category_number,
                        }))
                    );

                    if (result.data[0].course_mandatory) {
                        setIsMandatory(true);
                        setTargetAudience(result.data[0].course_mandatory.department ? "specific" : "all");

                        // Automatically set the duration if it exists
                        if (result.data[0].course_mandatory.duration) {
                            const selectedLevelOption = [
                                { label: t("lvl2"), value: "2" },
                                { label: t("lvl4"), value: "4" },
                                { label: t("lvl6"), value: "6" },
                                { label: t("lvl8"), value: "8" }
                            ].find(option => option.value === result.data[0].course_mandatory.level);

                            if (selectedLevelOption) {
                                setSelectedLevel(selectedLevelOption);
                            }
                        }

                        // Check if department needs to be fetched
                        if (result.data[0].course_mandatory.department) {
                            const departmentToSelect = result.data[0].course_mandatory.department;

                            // Fetch departments if targeting a specific department
                            axios.get("/api/departments")
                                .then((response) => {
                                    const departmentData = response.data.map((dept) => ({
                                        label: dept.department_name,
                                        value: dept.department_number,
                                    }));
                                    setDepartmentOptions(departmentData);

                                    // Automatically select the department if it exists in the fetched data
                                    const autoSelectedDepartment = departmentData.find(
                                        (dept) => dept.value === departmentToSelect
                                    );
                                    if (autoSelectedDepartment) {
                                        setSelectedDepartment(autoSelectedDepartment);
                                    }
                                })
                                .catch((error) => {
                                    console.error("Error fetching departments:", error);
                                    toast.error(t("Failed to load departments"));
                                });
                        }
                    }

                    setStartDate(result.data[0].course_start_date);
                    setEndDate(result.data[0].course_end_date);
                    setCreationDate(result.data[0].course_creation_date);
                    setCourseStatus(result.data[0].course_status);

                    const response = await axios.get('/api/course_category');
                    setCourseCategoryOptions(response.data.map(e => ({
                        label: e.course_category_number,
                        value: e.course_category_number,
                    })));

                    setLoadingPage(false);
                } else {
                    return navigate('/admin/courses');
                }

            }).catch((err) => {
                console.log(err);
            });
        } else return navigate('/admin/courses');
    }, []);

    if (loadingPage) return (
        <Flex
            pt={{ base: "130px", md: "80px", xl: "80px" }}
            height="100vh"
            alignItems="center"
            justifyContent="center"
        >
            <Spinner
                thickness="8px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl" // You can change this to "lg" or "2xl" if you need it even bigger
            />
        </Flex>
    )

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <ToastContainer rtl={i18n.language === "ar"} /> {/* Add the ToastContainer to render the toasts */}
            <Card
                direction="column"
                w="100%"
                px="0px"
                overflowX={{ sm: "scroll", lg: "hidden" }}
            >
                <VStack
                    spacing={4}
                    align="start"
                    w={{ base: "75%", md: "500px" }}
                    mx="auto"
                    my={4}
                >
                    <Box w="100%">
                        <Text fontSize="sm" color={inputText} mb={2}>
                            {t("course_name")}
                        </Text>
                        <Input
                            isInvalid={courseNameErrored}
                            errorBorderColor='crimson'
                            variant={courseNameErrored ? 'outline' : 'search'}
                            value={courseName}
                            onChange={(e) => {
                                setCourseName(e.target.value)
                                setCourseNameErrored(false)
                            }}
                            fontSize='sm'
                            bg={inputBg}
                            color={inputText}
                            fontWeight='500'
                            _placeholder={{ color: "gray.400", fontSize: "14px" }}
                            borderRadius={"5px"}
                            placeholder={t("course_name")}
                        />
                    </Box>
                    <Box w="100%">
                        <Text fontSize="sm" color={inputText} mb={2}>
                            {t("course_id")}
                        </Text>
                        <Input
                            isInvalid={courseIdErrored}
                            errorBorderColor='crimson'
                            variant={courseIdErrored ? 'outline' : 'search'}
                            value={courseId}
                            onChange={(e) => {
                                setCourseId(e.target.value)
                                setCourseIdErrored(false)
                            }}
                            fontSize='sm'
                            bg={inputBg}
                            color={inputText}
                            fontWeight='500'
                            _placeholder={{ color: "gray.400", fontSize: "14px" }}
                            borderRadius={"5px"}
                            placeholder={t("course_id")}
                        />
                    </Box>
                    <Box w="100%">
                        <Text fontSize="sm" color={inputText} mb={2}>
                            {t("course_category_number")}
                        </Text>
                        <Select
                            isInvalid={courseCategoryErrored}
                            errorBorderColor='crimson'
                            variant={courseCategoryErrored ? 'outline' : 'filled'}
                            value={courseCategory}
                            onChange={(val) => {
                                setCourseCategory(val); // Set as an array of selected options
                                setCourseCategoryErrored(false);
                            }}
                            isMulti
                            fontSize='sm'
                            bg={inputBg}
                            color={inputText}
                            fontWeight='500'
                            _placeholder={{ color: "gray.400", fontSize: "14px" }}
                            borderRadius={"5px"}
                            placeholder={t("course_categories")}
                            options={courseCategoryOptions}
                        />

                    </Box>
                    <Box w="100%">
                        <Text fontSize="sm" color={inputText} mb={2}>
                            {t("course_status")}
                        </Text>
                        <HStack spacing={0} w="100%">
                            <Button
                                colorScheme={courseStatus === "active" ? "blue" : "gray"}
                                variant={courseStatus === "active" ? "solid" : "outline"}
                                w="33.33%"
                                borderRightRadius={0}
                                onClick={() => setCourseStatus("active")}
                            >
                                {t("course_status_active")}
                            </Button>
                            <Button
                                colorScheme={courseStatus === "inactive" ? "red" : "gray"}
                                variant={courseStatus === "inactive" ? "solid" : "outline"}
                                w="33.33%"
                                borderRadius={0}
                                onClick={() => setCourseStatus("inactive")}
                            >
                                {t("course_status_inactive")}
                            </Button>
                            <Button
                                colorScheme={courseStatus === "completed" ? "green" : "gray"}
                                variant={courseStatus === "completed" ? "solid" : "outline"}
                                w="33.33%"
                                borderLeftRadius={0}
                                onClick={() => setCourseStatus("completed")}
                            >
                                {t("course_status_completed")}
                            </Button>
                        </HStack>
                    </Box>
                    <Box w="100%">
                        <HStack
                            w="100%"
                        >
                            <Box w="100%">
                                <Text fontSize="sm" color={startDateErrored ? 'crimson' : inputText} mb={2}>
                                    {t("start_date")} <Text as="span" color="red.500">*</Text>
                                </Text>
                                <DatePicker2 selectedDate={startDate} onChange={(date) => setStartDate(date)} />
                            </Box>
                            <Box w="100%">
                                <Text fontSize="sm" color={endDateErrored ? 'crimson' : inputText} mb={2}>
                                    {t("end_date")} <Text as="span" color="red.500">*</Text>
                                </Text>
                                <DatePicker2 selectedDate={endDate} onChange={(date) => setEndDate(date)} />
                            </Box>
                        </HStack>
                    </Box>
                    <Box w="100%">
                        <Text fontSize="sm" color={inputText} mb={2}>
                            {t("course_mandatory")} <Text as="span" color="red.500">*</Text>
                        </Text>
                        <HStack spacing={0} w="100%">
                            <Button
                                w="50%"
                                onClick={() => setIsMandatory(false)}
                                colorScheme={isMandatory === false ? "blue" : "gray"}
                                borderRightRadius={0}
                            >
                                {t("not_mandatory")}
                            </Button>
                            <Button
                                w="50%"
                                borderLeftRadius={0}
                                onClick={() => setIsMandatory(true)}
                                colorScheme={isMandatory === true ? "blue" : "gray"}
                            >
                                {t("mandatory")}
                            </Button>
                        </HStack>
                    </Box>

                    {isMandatory && (
                        <VStack spacing={4} w="100%">
                            <Box w="100%">
                                <Text fontSize="sm" color={inputText} mb={2}>
                                    {t("select_class")} <Text as="span" color="red.500">*</Text>
                                </Text>
                                <HStack spacing={0} w="100%">
                                    <Button
                                        colorScheme={targetAudience === "specific" ? "blue" : "gray"}
                                        variant={targetAudience === "specific" ? "solid" : "outline"}
                                        w="50%"
                                        borderRightRadius={0}
                                        onClick={() => setTargetAudience("specific")}
                                    >
                                        {t("specific_department")}
                                    </Button>
                                    <Button
                                        colorScheme={targetAudience === "all" ? "blue" : "gray"}
                                        variant={targetAudience === "all" ? "solid" : "outline"}
                                        w="50%"
                                        borderLeftRadius={0}
                                        onClick={() => setTargetAudience("all")}
                                    >
                                        {t("everyone")}
                                    </Button>
                                </HStack>
                            </Box>
                            {targetAudience === "all" && (
                                <Box w="100%">
                                    <Text fontSize="sm" color={inputText} mb={2}>
                                        {t("select_duration")} <Text as="span" color="red.500">*</Text>
                                    </Text>
                                    <Select
                                        isInvalid={selectedLevelErrored}
                                        errorBorderColor='crimson'
                                        variant={selectedLevelErrored ? null : 'filled'}
                                        fontSize='sm'
                                        bg={inputBg}
                                        color={inputText}
                                        fontWeight='500'
                                        _placeholder={{ color: "gray.400", fontSize: "14px" }}
                                        borderRadius={"5px"}
                                        placeholder={t("select_duration")}
                                        menuPortalTarget={document.body}
                                        options={[
                                            { label: t("lvl2"), value: "2" },
                                            { label: t("lvl4"), value: "4" },
                                            { label: t("lvl6"), value: "6" },
                                            { label: t("lvl8"), value: "8" }
                                        ]}
                                        value={selectedLevel}
                                        onChange={(val) => {
                                            setSelectedLevel(val)
                                            setSelectedLevelErrored(false)
                                        }}
                                    />
                                </Box>
                            )}
                            {targetAudience === "specific" && (
                                <Box w="100%">
                                    <Text fontSize="sm" color={inputText} mb={2}>
                                        {t("select_department")} <Text as="span" color="red.500">*</Text>
                                    </Text>
                                    <Select
                                        isInvalid={selectedDepartmentErrored}
                                        errorBorderColor='crimson'
                                        variant={selectedDepartmentErrored ? null : 'filled'}
                                        fontSize='sm'
                                        bg={inputBg}
                                        color={inputText}
                                        fontWeight='500'
                                        _placeholder={{ color: "gray.400", fontSize: "14px" }}
                                        borderRadius={"5px"}
                                        placeholder={t("select_department")}
                                        options={departmentOptions}
                                        value={selectedDepartment}
                                        menuPortalTarget={document.body}
                                        onChange={(val) => {
                                            setSelectedDepartment(val)
                                            setSelectedDepartmentErrored(false)
                                        }}
                                    />
                                    <Text fontSize="sm" color={inputText} mb={2} mt={2}>
                                        {t("select_duration")} <Text as="span" color="red.500">*</Text>
                                    </Text>
                                    <Select
                                        isInvalid={selectedLevelErrored}
                                        errorBorderColor='crimson'
                                        variant={selectedLevelErrored ? null : 'filled'}
                                        fontSize='sm'
                                        bg={inputBg}
                                        color={inputText}
                                        fontWeight='500'
                                        _placeholder={{ color: "gray.400", fontSize: "14px" }}
                                        borderRadius={"5px"}
                                        placeholder={t("select_duration")}
                                        menuPortalTarget={document.body}
                                        options={[
                                            { label: t("lvl2"), value: "2" },
                                            { label: t("lvl4"), value: "4" },
                                            { label: t("lvl6"), value: "6" },
                                            { label: t("lvl8"), value: "8" }
                                        ]}
                                        value={selectedLevel}
                                        onChange={(val) => {
                                            setSelectedLevel(val)
                                            setSelectedLevelErrored(false)
                                        }}
                                    />
                                </Box>
                            )}
                        </VStack>
                    )}
                    <Box w="100%">
                        <Button
                            align='center'
                            justifyContent='center'
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            w='100%'
                            h='44px'
                            lineHeight='100%'
                            borderRadius='5px'
                            onClick={handleUpdate}
                            disabled={loading} // Disable the button while loading
                        >
                            {loading ? (
                                <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='blue.500'
                                />
                            ) : (
                                <Text fontSize="sm" color={inputText}>
                                    {t("edit_course")}
                                </Text>
                            )}
                        </Button>
                    </Box>
                </VStack>
            </Card>
        </Box>
    );
}
