import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios'

// Chakra imports
import {
  Box,
  IconButton,
  Icon,
  SimpleGrid,
  useColorModeValue,
  Flex,
  Spinner,
  Text,
  Image,
} from "@chakra-ui/react";

// Translation
import { useTranslation } from 'react-i18next';

// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import DateFormatter from 'components/dateFormatter/DateFormatter';
import Plus from "components/buttons/Plus";

// React Toastify for notifications
import { toast, ToastContainer } from 'react-toastify';

// Table data
import ColumnsTable from "./ColumnsTable";

// Icons
import {
  MdDateRange,
  MdSafetyCheck,
  MdBarChart,
  MdTitle,
  MdEdit,
  MdDelete,
  MdCheck,
  MdError,
  MdRocketLaunch,
  MdPrint
} from "react-icons/md";
import { useReactToPrint } from "react-to-print"

export default function Settings() {

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef, copyShadowRoots: true });

  // Chakra Color Mode
  const bgDelButton = useColorModeValue("secondaryGray.300", "red.800");
  const bgEditButton = useColorModeValue("secondaryGray.300", "blue.800");
  const [deleteDisabled, setDeleteDisabled] = useState(false)
  const bgDelHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "red.600" }
  );
  const bgEditHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "blue.500" }
  );

  async function handleDelete() {
    setDeleteDisabled(true)
    axios({
      method: 'DELETE',
      url: `/api/user/${searchParams.get("soldier")}`,
    }).then(async (result) => {
      navigate('/admin/soldiers');

    }).catch(async (err) => {
      console.log(err)
    })
  }

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const { t, i18n } = useTranslation()
  const [userData, setUserData] = useState(null)

  useEffect(() => {

    if (!searchParams.get("soldier")) return navigate('/admin/soldiers');

    axios({
      method: 'GET',
      url: `/api/user/${searchParams.get("soldier")}`,
    }).then(async (result) => {
      setUserData(result.data)

    }).catch(async (err) => {
      console.log(err)
    })
  }, [i18n.language, searchParams.get("soldier")])

  if (!userData) return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner
        thickness="8px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl" // You can change this to "lg" or "2xl" if you need it even bigger
      />
    </Flex>
  )

  return (
    <Box ref={contentRef} pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer rtl={i18n.language === "ar"} />
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 1 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <Flex
              borderRadius="full"
              w='150px'
              h='150px'
              color="white"
              display="flex"
              bg={"green.400"}
              justifyContent="center" // Center the content horizontally
              alignItems="center" // Center the content vertically
            >
              <Image
                w={"140px"}
                aria-label="Image"
                src={userData.soldier_image ? `/api/images/${userData.soldier_image}` : require('../../../../assets/img/user_placeholder.jpg')}
                borderRadius={"full"}
                mx={5}
              />
            </Flex>
          }
          endContent={
            <Flex>
              <IconButton
                w='56px'
                h='56px'
                aria-label="Edit"
                borderRadius={"xl"}
                bg={bgEditButton}
                _hover={bgEditHover}
                icon={<MdEdit />}
                onClick={() => navigate(`/admin/soldiers/edit?soldier=${searchParams.get("soldier")}`)} // Add your edit handler here
                mx={1}
                fontSize="25px"
              />
              <IconButton
                w='56px'
                h='56px'
                aria-label="Delete"
                borderRadius={"xl"}
                bg={bgDelButton}
                _hover={bgDelHover}
                icon={<MdDelete />}
                disabled={deleteDisabled}
                onClick={handleDelete} // Add your delete handler here
                mx={1}
                fontSize="25px"
              />

            </Flex>
          }
          name={t("soldier_full_name")}
          value={<Text fontSize="4xl">{userData.soldier_full_name}</Text> }
        />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name={t("courses_in_category")}
          value={userData.courses.length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdDateRange} color={brandColor} />
              }
            />
          }
          name={t("soldier_date_of_hiring")}
          value={<DateFormatter date={userData.soldier_date_of_hiring} locale={i18n.language} />}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdDateRange} color={brandColor} />
              }
            />
          }
          name={t("soldier_rank")}
          value={userData.soldier_rank}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdSafetyCheck} color={brandColor} />
              }
            />
          }
          name={t("soldier_level")}
          value={t(`lvl${userData.soldier_level}`)}
        />
      </SimpleGrid>

      {
        userData.courses.length ? (
          <ColumnsTable
            columnsData={[
              {
                "Header": "course_id",
                "accessor": "course_id",
                "show": true,
                "required": false
              },
              {
                "Header": "course_name",
                "accessor": "course_name",
                "show": true,
                "required": true
              },
              {
                "Header": "start_date",
                "accessor": "course_start_date",
                "show": true,
                "required": true
              },
              {
                "Header": "end_date",
                "accessor": "course_end_date",
                "show": true,
                "required": true
              },
              {
                "Header": "soldier_level",
                "accessor": "course_mandatory",
                "show": true,
                "required": true
              },
              {
                "Header": "course_status",
                "accessor": "course_status",
                "show": true,
                "required": true
              },
              {
                "Header": "course_creation_date",
                "accessor": "course_creation_date",
                "show": true,
                "required": true
              }
            ]}
            tableData={userData.courses}
          />
        ) : (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 1 }} gap='20px' mb='20px'>
            <Flex justifyContent='center' alignItems='center' h='100%'>
              <MiniStatistics endContent={
                <Flex>
                  <Plus me="10px" href={`/admin/soldiers/assign?soldier=${searchParams.get("soldier")}`} />
                </Flex>
              }
                value={
                  <Text textAlign='center'>{t("no_courses")}</Text>
                } />
            </Flex>
          </SimpleGrid>
        )
      }
    </Box>
  );
}
