import React, { useEffect, useState } from "react";
// Chakra imports
import { Box, Input, Text, VStack, HStack, useColorModeValue, Button, Spinner } from "@chakra-ui/react";
// Translation
import { useTranslation } from 'react-i18next';
// Axios for HTTP requests
import axios from 'axios';
// React Router
import { useNavigate, useSearchParams } from 'react-router-dom';
// React Toastify for notifications
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Custom components
import Card from "components/card/Card";

export default function Settings() {

  // Chakra Color Mode
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue({ bg: "secondaryGray.400" }, { bg: "whiteAlpha.50" });
  const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

  const { t, i18n } = useTranslation();
  const [courseCategory, setCourseCategoryNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [dateChange, setDateChange] = useState(null)
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!courseCategory) {
      toast.error(i18n.language === 'ar' ? 'رقم الفئة مطلوب' : 'Unit number is required');
      return;
    }

    setLoading(true);

    try {
      await axios.post('/api/course_category', {
        course_category_number: courseCategory,
      });

      // Clear the form fields and navigate to the new page
      setCourseCategoryNumber('');
      navigate('/admin/course_category');
    } catch (error) {
      // Extract and log the error message
      const errorMessage = error.response?.data?.error || error.message;

      // Check if the error is related to existing course category
      if (error.response?.data?.error_code === 'ERR_COURSE_CATEGORY_EXISTS') {
        toast.error(i18n.language === 'ar' ? 'فئة الدورة بهذا الرقم موجودة بالفعل.' : 'Course category with this number already exists.');
      } else {
        console.error("Error creating course_category identification:", errorMessage);
        toast.error(errorMessage);
      }
    } finally {
      // Ensure loading state is always turned off
      setLoading(false);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer rtl={i18n.language === "ar"} /> {/* Add the ToastContainer to render the toasts */}
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <VStack
          spacing={4}
          align="start"
          w={{ base: "75%", md: "500px" }}
          mx="auto"
          my={4}
        >
          <Box w="100%">
            <Text fontSize="sm" color={inputText} mb={2}>
              {t("course_category_number")} <Text as="span" color="red.500">*</Text>
            </Text>
            <Input
              value={courseCategory}
              onChange={(e) => setCourseCategoryNumber(e.target.value)}
              variant='search'
              fontSize='sm'
              bg={inputBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "14px" }}
              borderRadius={"5px"}
              placeholder={t("course_category_number")}
            />
          </Box>

          <Box w="100%">
            <Button
              align='center'
              justifyContent='center'
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              w='100%'
              h='44px'
              lineHeight='100%'
              borderRadius='5px'
              onClick={handleSubmit}
              disabled={loading} // Disable the button while loading
            >
              {loading ? (
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                />
              ) : (
                <Text fontSize="sm" color={inputText}>
                  {t("create_courses_category")}
                </Text>
              )}
            </Button>
          </Box>
        </VStack>
      </Card>
    </Box>
  );
}
