import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Button,
  Text,
  Spinner,
} from "@chakra-ui/react";
import Usa from "assets/img/dashboards/usa.png";
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import DatePicker2 from "../../../components/datePicker/ReactDatePicker";
import {
  MdGroupAdd,
  MdSchool,
  MdPeople,
  MdHowToReg,
  MdExitToApp,
  MdCalendarToday,
} from "react-icons/md";
import PieCard from "views/admin/default/components/PieCard";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export default function UserReports() {
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [numberOfSoldiers, setNumberOfSoldiers] = useState(0);
  const [numberOfCourses, setNumberOfCourses] = useState(0);
  const [numberSoilderStatusAffiliate, setNumberSoilderStatusAffiliate] = useState(0);
  const [numberSoilderStatusWithdrawn, setNumberSoilderStatusWithdrawn] = useState(0);
  const [numberSoilderStatusCandidate, setNumberSoilderStatusCandidate] = useState(0);
  const [pieChartData, setPieChartData] = useState([1, 1, 1]);

  useEffect(() => {
    const fetchStatistic = async () => {
      try {
        // Build query parameters based on date selections
        const params = new URLSearchParams();
        if (startDate) {
          params.append('start_date', startDate.toISOString().split('T')[0]);
        }
        if (endDate) {
          params.append('end_date', endDate.toISOString().split('T')[0]);
        }

        // Construct URL with query parameters
        console.log(`/api/statics${params.toString() ? '?' + params.toString() : ''}`)
        const url = `/api/statics${params.toString() ? '?' + params.toString() : ''}`;
        
        const res = await axios.get(url);
        const data = res.data;

        // Set state variables based on the response
        setNumberOfSoldiers(data.totalUsers || 0);
        setNumberOfCourses(data.totalCourses || 0);
        setNumberSoilderStatusAffiliate(data.soldiersPerStatus.affiliate || 0);
        setNumberSoilderStatusWithdrawn(data.soldiersPerStatus.withdrawn || 0);
        setNumberSoilderStatusCandidate(data.soldiersPerStatus.candidate || 0);
        setPieChartData([
          data.coursesPerStatus.completed,
          data.coursesPerStatus.active,
          data.coursesPerStatus.inactive
        ]);
      } catch (error) {
        console.error('Error fetching statistic:', error);
      }
    };

    fetchStatistic();
  }, [startDate, endDate]); // Dependency array includes both dates

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.900");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue({ bg: "secondaryGray.400" }, { bg: "whiteAlpha.50" });
  const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });
  const inputText = useColorModeValue("white", "white");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 3 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdCalendarToday} color={brandColor} />
              }
            />
          }
          name={t("start_date")}
          value={
            <Box position="relative" zIndex="100">
              <Flex alignItems="center" gap="2">
                <DatePicker2 
                  selectedDate={startDate} 
                  onChange={(date) => setStartDate(date)}
                />
                <Box w="120px">
                  <Button
                    align='center'
                    justifyContent='center'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w='100%'
                    h='44px'
                    lineHeight='100%'
                    borderRadius='5px'
                    onClick={() => setStartDate(null)}
                  >
                    <Text fontSize="sm" color={inputText}>
                      {t("reset_date")}
                    </Text>
                  </Button>
                </Box>
              </Flex>
            </Box>
          }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdCalendarToday} color={brandColor} />
              }
            />
          }
          name={t("end_date")}
          value={
            <Box position="relative" zIndex="99">
              <Flex alignItems="center" gap="2">
                <DatePicker2 
                  selectedDate={endDate} 
                  onChange={(date) => setEndDate(date)}
                />
                <Box w="120px">
                  <Button
                    align='center'
                    justifyContent='center'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w='100%'
                    h='44px'
                    lineHeight='100%'
                    borderRadius='5px'
                    onClick={() => setEndDate(null)}
                  >
                    <Text fontSize="sm" color={inputText}>
                      {t("reset_date")}
                    </Text>
                  </Button>
                </Box>
              </Flex>
            </Box>
          }
        />
      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdPeople} color={brandColor} />
              }
            />
          }
          name={t("soldiers")}
          value={numberOfSoldiers}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdSchool} color={brandColor} />
              }
            />
          }
          name={t("military_courses")}
          value={numberOfCourses}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdGroupAdd} color={brandColor} />
              }
            />
          }
          name={t("soilder_status_affiliate")}
          value={numberSoilderStatusAffiliate}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdExitToApp} color={brandColor} />
              }
            />
          }
          name={t("soilder_status_withdrawn")}
          value={numberSoilderStatusWithdrawn}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdHowToReg} color={brandColor} />
              }
            />
          }
          name={t("soilder_status_candidate")}
          value={numberSoilderStatusCandidate}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap='20px' mb='20px'>
        <PieCard pieChartData={pieChartData} totalCourses={numberOfCourses}/>
      </SimpleGrid>
    </Box>
  );
}